<template>
    <!-- 管理员- 人员统计 -->
    <div class="personnel wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between" @submit.native.prevent>
            <div class="formInline-left">
                <el-date-picker size="mini" v-model="formInline.datetimerange" type="datetimerange"
                    start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" @change="filterChange"
                    :editable="false" value-format="yyyy-MM-dd HH:mm:ss" class="date-picker">
                </el-date-picker>
            </div>
            <div class="formInline-right flex-align-between">
                <el-input size="mini" v-model="formInline.search" placeholder="按姓名和手机号" suffix-icon="el-icon-search"
                    @input="filterChange">
                </el-input>
            </div>
            <div class="download-inline">
                <span class="export-btn csp" @click="exportFile" style="margin-right:10px">
                    <i class="export-icon"></i>导出</span>
                <span class="export-btn csp" @click="refreshData">
                    <i class="el-icon-refresh"></i>刷新数据</span>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" fixed
                    :index="indexMethod">
                </el-table-column>
                <el-table-column prop="name" label="教师姓名" align="center" width="120" fixed>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="userAreaName" label="地区" align="center" width="180">
                </el-table-column>
                <el-table-column prop="schoolName" label="学校" align="center" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="liveTime" :label="'直播学习总时长'" align="center" width="120">
                    <template slot-scope="scope">
                        {{parseInt(scope.row.liveTime/60)}}′{{scope.row.liveTime%60}}″
                    </template>
                </el-table-column>
                <el-table-column prop="replayTime" :label="'回放总时长'" align="center" width="120">
                    <template slot-scope="scope">
                        {{parseInt(scope.row.replayTime/60)}}′{{scope.row.replayTime%60}}″
                    </template>
                </el-table-column>
                <el-table-column prop="recordTime" :label="'异步课学习总时长'" align="center" width="140">
                    <template slot-scope="scope">
                        {{parseInt(scope.row.recordTime/60)}}′{{scope.row.recordTime%60}}″
                    </template>
                </el-table-column>
                <el-table-column prop="netCourseTime" :label="'网络课学习总时长'" align="center" width="140">
                    <template slot-scope="scope">
                        {{parseInt(scope.row.netCourseTime)}}′{{scope.row.netCourseTime%60}}″
                    </template>
                </el-table-column>
                <el-table-column prop="questCount" :label="'提交作业数量\n(单位：个)'" align="center" width="120">
                </el-table-column>
            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10,20,30]" @pagination="handlePageChange">
            </pagination>
        </div>
    </div>

</template>

<script>
    import upload from './components/upload-file.vue';
    import {
        cloneDeep
    } from 'lodash/lang'

    export default {
        components: {
            upload
        },
        data() {
            return {
                activeName: "",
                tableData: [],
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                formInline: {
                    datetimerange: '',
                    search: '',
                },
                loadingInstance: ''
            };
        },
        methods: {
            indexMethod(index) {
                return this.pages.currentPage > 1 ?
                    (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                    index + 1
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getUserStatisticInfo();
            },
            filterChange() {
                this.pages.currentPage = 1;
                this.getUserStatisticInfo();
            },
            refreshData() {
                this.formInline.datetimerange = '';
                this.formInline.search = '';
                this.pages.currentPage = 1;
                this.loadingInstance = this.$loading({
                    // fullscreen:false,
                    lock: true,
                    text: '数据刷新中...',
                    spinner: 'el-icon-loading',
                });
                let data = {
                    projectId: this.$store.state.user.currentProject.id
                }
                this.$Api.Project.sendRefresh(data)
                    .then(res => {
                        console.log('发送刷新成功', res);
                        this.getUserStatisticInfo();
                    })
                    .catch(err => {
                        console.log('发送刷新失败', err)
                    })
            },
            // 获取周报列表
            async getUserStatisticInfo() {
                let params = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: this.$store.state.user.currentProject.id,
                }
                params.keys = this.formInline.search ? this.formInline.search : null;
                params.startTime = this.formInline.datetimerange ? this.formInline.datetimerange[0] : null;
                params.finishTime = this.formInline.datetimerange ? this.formInline.datetimerange[1] : null;
                // console.log(pramas);
                let resData = await this.$Api.Project.getUserStatisticInfo(params);
                // console.log(resData.data);
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
                if (this.loadingInstance) {
                    this.loadingInstance.close()
                }
            },
            async exportFile() {
                // let params = {
                //     projectId: this.$store.state.user.currentProject.id,
                // }
                // let resData = await this.$Api.Project.exportUserStatisticInfo(params)
                // console.log(resData);
                this.$message.info('正在导出...')
                let id = this.$store.state.user.currentProject.id
                window.location.href =
                    `${this.commonbaseURL}/score/projectHome/getUserStatisticInfo/export?projectId=${id}`;
            },
            init() {
                this.activeName = this.$store.state.user.currentProject.id;
            },
            // 查询刷新状态
            async checkRefresh() {
                let data = {
                    projectId: this.$store.state.user.currentProject.id
                }
                let resData = await this.$Api.Project.checkRefresh(data)
                console.log('查询刷新状态', resData);
                if (resData.msg = "未刷新或刷新完成") {
                    console.log('刷新完成关闭loading');
                    if (this.loadingInstance) {
                        this.loadingInstance.close()
                    }
                } else {
                    console.log('正在刷新显示loading');
                    this.loadingInstance = this.$loading({
                        lock: true,
                        text: '数据刷新中...',
                        spinner: 'el-icon-loading',
                    });
                }
            }
        },
        activated() {
            this.init();
            this.getUserStatisticInfo();
            this.checkRefresh()
        },
        mounted() {
            this.init();
            this.getUserStatisticInfo();
            this.checkRefresh()
        },
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .personnel {
        padding: 20px 25px;
        box-sizing: border-box;

        .demo-form-inline {
            margin: 15px 0;

            .date-picker {
                width: 320px;
            }

            @media screen and (min-width:1250px) {
                margin: 20px 0;

                .date-picker {
                    width: 400px;
                }
            }
        }

        .download-inline {
            .export-btn {
                color: #595959;
            }

            .export-icon {
                display: inline-block;
                background-image: url("~@/assets/images/admin/export.png");
                background-size: 100% 100%;
                width: 17px;
                height: 16px;
                margin-right: 8px;
                vertical-align: -2px;
            }
        }

        .table_section {
            /deep/.el-table th {
                &>.cell {
                    white-space: pre-line;
                }
            }
        }
    }
</style>